import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {HeaderComponent} from './components/header/header.component'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {AuthInterceptor} from './application/auth.interceptor'
import {ResponseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'
import {LoanService} from './services/loan.service'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ThemeModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [
    // The Config service depends on the SSO Service and the
    // APP_INITIALIZER requires the ConfigService
    LoanService,
    SingleSignOnService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    // Provide the interceptors
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
